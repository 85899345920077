import React from "react";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HelpfulHeroesTextField from "../../shared/HelpfulHeroesTextField";
import { useProjectDetails } from "../../../hooks/useProject";

const useStyles = makeStyles(() => ({
  equipmentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "2rem",
  },
  equipmentOptions: {
    display: "flex",
    width: "100%",
  },
}));

const Equipemnt = ({ control, setDescriptionFormValue, errors }) => {
  const classes = useStyles();
  const { projectDetails } = useProjectDetails();
  const [specialEquipment, setSpecialEquipment] = React.useState(
    projectDetails?.specialEquipment || "yes"
  );

  return (
    <div className={classes.equipmentContainer}>
      <Typography variant="title1">
        Is there special equipment needed?
      </Typography>
      <div className={classes.equipmentOptions}>
        <Button
          variant="contained"
          color={(specialEquipment === "yes" && "navy") || "lightGrey"}
          style={{
            color: (specialEquipment === "yes" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={() => {
            setSpecialEquipment("yes");
            setDescriptionFormValue("special_equipment", "yes");
          }}
          name="yes"
          control={control}
          controller
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color={(specialEquipment === "no" && "navy") || "lightGrey"}
          style={{
            color: (specialEquipment === "no" && "white") || "navy",
            marginRight: 8,
          }}
          name="no"
          control={control}
          controller
          onClick={() => {
            setSpecialEquipment("no");
            setDescriptionFormValue("special_equipment", "no");
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          color={(specialEquipment === "maybe" && "navy") || "lightGrey"}
          style={{
            color: (specialEquipment === "maybe" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={async () => {
            setSpecialEquipment("maybe");
            setDescriptionFormValue("special_equipment", "maybe");
          }}
          name="maybe"
          control={control}
          controller
        >
          I’m not sure
        </Button>
      </div>
      {specialEquipment === "yes" && (
        <HelpfulHeroesTextField
          control={control}
          controller
          name="equipmentDetails"
          placeholder="Please describe any equipment that might be needed."
          sx={{ width: { md: "100%", xs: "100%" }, mt: "1rem" }}
          error={errors?.equipmentDetails}
          helperText={errors?.equipmentDetails?.message}
        />
      )}
    </div>
  );
};

export default Equipemnt;
