import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CreditCardForm from "../CreditCardForm";

const PaymentMethodsForm = () => {
  const stripeLoaded = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements stripe={stripeLoaded}>
      <CreditCardForm isAtProjects={false} />
    </Elements>
  );
};

export default PaymentMethodsForm;
