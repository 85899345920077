import { useSelector, shallowEqual } from "react-redux";

export const useAllProjectTypes = () => {
  return {
    allProjectTypes: useSelector(
      (state) => state.project.allProjectTypes?.services,
      shallowEqual
    ),
  };
};

export const useProjectDetails = () => {
  return {
    projectDetails: useSelector(
      (state) => state.project.projectDetails,
      shallowEqual
    ),
  };
};
