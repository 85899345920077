import { useSelector, shallowEqual } from "react-redux";

const useJobs = () =>
  useSelector(
    ({ session: { jobs } }) => ({
      jobs,
    }),
    shallowEqual
  );

export default useJobs;
