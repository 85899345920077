import { createSlice } from "@reduxjs/toolkit";
import {
  loginFulfilled,
  logout,
  signUpFulfilled,
  getUserFulfilled,
  updateSession,
  getChatBoardsFulfilled,
  getNotificationsFulfilled,
  updateUserFulfilled,
  getJobsFulfilled,
  resetPasswordFulfilled,
  updateAuthInfoFulfilled,
  getCreditCardsFulfilled,
  resendVerifyCodeFulfilled,
  addCreditCardFulfilled,
  newNotificationsFulfilled,
} from "../actions/userActions";

const initialState = {
  authenticated: false,
  user: null,
  info: {},
  notifications: [],
  unseenNotifications: false,
  chatBoards: [],
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  extraReducers: {
    [loginFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [signUpFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [logout]: () => initialState,
    [updateSession]: (state, { payload }) => {
      state.info = payload;
      state.authenticated = true;
    },
    [updateAuthInfoFulfilled]: (state, { payload }) => {
      state.info = {
        token: payload["access-token"],
        uid: payload.uid,
        client: payload.client,
      };
    },
    [resendVerifyCodeFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [resetPasswordFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [getUserFulfilled]: (state, { payload }) => {
      state.user = payload.user;
    },
    [getNotificationsFulfilled]: (state, { payload }) => {
      state.notifications = payload.notifications;
    },
    [newNotificationsFulfilled]: (state, { payload }) => {
      state.unseenNotifications = payload.unseenNotifications;
    },
    [getChatBoardsFulfilled]: (state, { payload }) => {
      state.chatBoards = payload.chatBoards;
    },
    [getJobsFulfilled]: (state, { payload }) => {
      state.jobs = payload?.jobs;
    },
    [updateUserFulfilled]: (state, { payload }) => {
      state.user = payload.user;
    },
    [getCreditCardsFulfilled]: (state, { payload }) => {
      state.creditCard = payload;
    },
    [addCreditCardFulfilled]: (state) => {
      state.user.hasPaymentMethod = true;
    },
  },
});

export default sessionSlice.reducer;
