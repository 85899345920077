import * as yup from "yup";

export const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters long"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is a required field"),
});
