import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useIntercom } from "react-use-intercom";
import { LEGAL_LINKS } from "../../../utils/contants";

const useStyles = makeStyles((theme) => ({
  isMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  icons: {
    display: "flex",
    alignItems: "center",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: "2rem",
      marginBottom: 36,
      marginTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0.5rem",
    },
  },
  bottomBar: {
    marginTop: "3rem",
    width: "100%",
    height: "4.375rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    bottom: 0,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  texts: {
    paddingLeft: "10rem",
    display: "flex",
    alignItems: "baseline",
    height: "1.7rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      flexDirection: "column",
      alignItems: "center",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
    },
  },
  links: {
    width: "20rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "13.25rem",
    alignSelf: "center",
    alignItems: "baseline",
  },
}));

const BottomBar = () => {
  const classes = useStyles();
  const { boot, show } = useIntercom();

  const handleOpenContactSupport = () => {
    boot();
    show();
  };

  return (
    <div className={classes.bottomBar}>
      <div className={classes.texts}>
        <div className={classes.links}>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            © 2024 Helpful Heroes
          </Typography>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: "#acacac", cursor: "pointer" }}
            onClick={() => window.open(LEGAL_LINKS.PRIVACY_POLICY)}
          >
            Privacy
          </Typography>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: "#acacac", cursor: "pointer" }}
            onClick={() => window.open(LEGAL_LINKS.TERMS_OF_USE)}
          >
            Terms
          </Typography>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: "#acacac", cursor: "pointer" }}
            onClick={() => handleOpenContactSupport()}
          >
            Support
          </Typography>
        </div>
      </div>
      <div className={classes.icons}>
        <Typography
          variant="body3"
          sx={{ color: "#acacac", mr: "1.7rem", cursor: "pointer" }}
          onClick={() => window.open(process.env.REACT_APP_WEB_URL)}
        >
          hirehelpfulheroes.com
        </Typography>
        <InstagramIcon
          sx={{ color: "#c1c1c1", cursor: "pointer" }}
          onClick={() => window.open("https://www.instagram.com/hidrent/")}
        />
        <FacebookIcon
          sx={{
            ml: "1.25rem",
            mr: "1.25rem",
            color: "#c1c1c1",
            cursor: "pointer",
          }}
          onClick={() => window.open("https://www.facebook.com/hidrent/")}
        />
        <TwitterIcon
          sx={{ color: "#c1c1c1", cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://twitter.com/HidrentApp?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            )
          }
        />
      </div>
    </div>
  );
};

export default BottomBar;
