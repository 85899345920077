import { useSelector, shallowEqual } from "react-redux";

const useChatBoards = () =>
  useSelector(
    ({ session: { chatBoards } }) => ({
      chatBoards,
    }),
    shallowEqual
  );

export default useChatBoards;
