/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import useChatBoards from "../../hooks/useChatBoards";
import { getProjectChatBoard } from "../../redux/actions/projectActions";
import { getChatBoards } from "../../redux/actions/userActions";
import ImageIcon from "@mui/icons-material/Image";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: "0 !important",
    alignItems: "center !important",
    cursor: "pointer",
  },
}));
const ChatList = ({ setIsMobile = () => {} }) => {
  const { chatBoards } = useChatBoards();
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchTerm = "data:image/";
  const visibleChats = chatBoards?.filter(
    (item) => item.jobStatus === "assigned"
  );

  React.useEffect(() => {
    dispatch(getChatBoards());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getProjectChatBoard(visibleChats?.at(0)?.jobId));
  }, [chatBoards]);

  const handleItemClick = (id) => {
    dispatch(getProjectChatBoard(id));
  };

  const textColor = (status) => {
    if (status === "completed") {
      return "#43b12e";
    } else if (
      status === "pending" ||
      status === "assigned" ||
      status === "unassigned"
    ) {
      return "#2b8ced";
    } else if (status === "cancelled") {
      return "#B90E31";
    }
  };

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {visibleChats?.map((chatBoard, index) => (
        <ListItem
          onClick={() => {
            handleItemClick(chatBoard.jobId);
            setIsMobile();
          }}
          className={classes.listItem}
          alignItems="flex-start"
          key={index}
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                width: "3.5rem",
                height: "3.5rem",
                marginRight: "1.5rem",
                bgcolor: "background.paper",
              }}
              src={chatBoard.jobImage}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                <Typography variant="title3">{chatBoard.jobTitle}</Typography>
                {chatBoard?.lastMessage?.lastIndexOf(searchTerm) ? (
                  <Typography sx={{ my: "0.1rem" }} variant="body2">
                    {chatBoard.lastMessage}
                  </Typography>
                ) : (
                  <div
                    style={{
                      my: "0.1rem",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ImageIcon />
                    <Typography>Image</Typography>
                  </div>
                )}
              </span>
            }
            secondary={
              <Typography
                sx={{
                  color: textColor(chatBoard.jobStatus),
                  textTransform: "capitalize",
                }}
              >
                {chatBoard.jobStatus}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ChatList;
