/* eslint-disable import/no-anonymous-default-export */
import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers/rootReducer";

export default (initialState, isServerSide = false) => {
  const store = configureStore({
    reducer,
    preloadedState: initialState,
  });

  if (isServerSide) {
    return { store };
  }

  const persistor = persistStore(store);

  return { store, persistor };
};
