import { useSelector, shallowEqual } from "react-redux";

const useSelectedChatBoard = () =>
  useSelector(
    ({ project: { selectedChatBoard } }) => ({
      selectedChatBoard,
    }),
    shallowEqual
  );

export default useSelectedChatBoard;
