import { useSelector, shallowEqual } from "react-redux";

const useSession = () =>
  useSelector(
    ({ session: { authenticated, user, info } }) => ({
      authenticated,
      user,
      info,
    }),
    shallowEqual
  );

export const useCreditCard = () =>
  useSelector(
    ({ session: { creditCard } }) => ({
      creditCard,
    }),
    shallowEqual
  );

export default useSession;
