import { useSelector, shallowEqual } from "react-redux";

const useNotifications = () =>
  useSelector(
    ({ session: { notifications } }) => ({
      notifications,
    }),
    shallowEqual
  );

export default useNotifications;
