import { useSelector, shallowEqual } from "react-redux";

const useNewNotifications = () =>
  useSelector(
    ({ session: { unseenNotifications } }) => ({
      unseenNotifications,
    }),
    shallowEqual
  );

export default useNewNotifications;
