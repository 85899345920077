import React from "react";
import { makeStyles } from "@mui/styles";
import TopBar from "../../components/shared/TopBar";
import ChatMessages from "../../components/ChatMessages";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatList from "../../components/ChatList";
import useChatBoards from "../../hooks/useChatBoards";
import useSelectedChatBoard from "../../hooks/useSelectedChatBoard";

const useStyles = makeStyles((theme) => ({
  leftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "30%",
  },
  messagesBody: {
    display: "flex",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  messagesContainer: {
    minHeight: "60vh",
  },
  chatMessages: {
    display: "flex",
    flexDirection: "column",
  },
  mobileView: {
    marginLeft: "2rem",
  },
}));

const Messages = () => {
  const classes = useStyles();
  const { chatBoards } = useChatBoards();
  const { selectedChatBoard } = useSelectedChatBoard();
  const [isMobile, setDesktop] = React.useState(window.innerWidth < 600);
  const [showMobileList, setShowMobileList] = React.useState(true);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 600);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className={classes.messages}>
      <TopBar />
      {(!isMobile && (
        <div className={classes.messagesBody}>
          <div className={classes.leftBlock}>
            <Typography variant="h2">Messages</Typography>
            <ChatList />
            {chatBoards?.filter((item) => item.jobStatus === "assigned")[0] ===
              undefined && (
              <Typography sx={{ mt: "30px" }}>No messages</Typography>
            )}
          </div>
          {chatBoards[0] !== undefined && (
            <ChatMessages
              jobId={
                chatBoards?.find((item) => item.id === selectedChatBoard.id)
                  ?.jobId
              }
            />
          )}
        </div>
      )) || (
        <div className={classes.mobileView}>
          <Typography variant="h2" textAlign="start">
            Messages
          </Typography>
          {(showMobileList && (
            <>
              <ChatList setIsMobile={() => setShowMobileList(false)} />
              {chatBoards?.filter(
                (item) => item.jobStatus === "assigned"
              )[0] === undefined && (
                <Typography sx={{ mt: "30px" }}>No messages</Typography>
              )}
            </>
          )) || (
            <div className={classes.chatMessages}>
              <IconButton
                sx={{
                  paddingLeft: "0",
                  alignSelf: "start",
                  marginBottom: "1rem",
                }}
                onClick={() => setShowMobileList(true)}
                marginBottom="23px"
              >
                <ArrowBackIcon sx={{ marginRight: "0.2rem" }} color="grey" />
                <Typography variant="body3" color="grey">
                  Back
                </Typography>
              </IconButton>
              {chatBoards[0] !== undefined && (
                <ChatMessages
                  jobId={
                    chatBoards?.find((item) => item.id === selectedChatBoard.id)
                      ?.jobId
                  }
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Messages;
