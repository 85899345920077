import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React from "react";
import { useForm } from "react-hook-form";
import useSession from "../../../hooks/useSession";
import { updateUser } from "../../../redux/actions/userActions";
import HelpfulHeroesPhoneInput from "../../shared/HelpfulHeroesPhoneField";
import HelpfulHeroesTextField from "../../shared/HelpfulHeroesTextField";
import LocationInput from "../../shared/LocationInput";

const useStyles = makeStyles((theme) => ({
  contactInformationFormRowInput: {
    marginBottom: "1rem !important",
  },
  inputsContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
      alignSelf: "center",
    },
  },
  inputsContainertwo: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "1.7rem",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
}));

const PersonalInfoForm = ({ image }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSession();
  const [locationValue, setLocationValue] = React.useState(
    user?.address?.fullAddress || null
  );
  const [inputValue, setInputValue] = React.useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const payload = !!locationValue
      ? {
          user: {
            ...data,
            addressesAttributes: [
              {
                id: user?.address?.id,
                fullAddress: locationValue?.description,
              },
            ],
            selectedAddressId: user?.address.id,
            profile_picture: image,
          },
        }
      : { user: data };

    const response = await dispatch(updateUser(payload));

    if (response?.error) {
      toast.error(
        <Typography color="error">{response?.error?.message}</Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }

    toast.success(
      <Typography color="success">
        Your Personal Info has been updated successfully.
      </Typography>,
      {
        position: "top-center",
      }
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.sectionContainer}>
        <Typography sx={{ mb: "0.8rem" }} variant="h5">
          Contact Information
        </Typography>
        <div className={classes.inputsContainer}>
          <HelpfulHeroesTextField
            name="firstName"
            control={control}
            defaultValue={user?.firstName}
            controller
            placeholder="First Name*"
            sx={{
              width: { lg: "100%", md: "40%", xs: "50%" },
              mr: "1rem",
              color: "navy",
            }}
            className={classes.contactInformationFormRowInput}
            error={errors?.firstName}
            helperText={errors.firstName && "First Name is required."}
          />
          <HelpfulHeroesTextField
            name="lastName"
            control={control}
            controller
            defaultValue={user?.lastName}
            className={classes.contactInformationFormRowInput}
            placeholder="Last Name*"
            sx={{ width: { lg: "100%", md: "40%", xs: "50%" } }}
            error={errors?.lastName}
            helperText={errors.lastName && "Last Name is required."}
          />
        </div>
        <div className={classes.inputsContainertwo}>
          <HelpfulHeroesTextField
            name="email"
            control={control}
            controller
            disabled
            defaultValue={user?.email}
            className={classes.contactInformationFormRowInput}
            placeholder="Email Address*"
            sx={{
              width: { lg: "100%", md: "40%", xs: "100%" },
              alignSelf: { xs: "center", sm: "start" },
              color: "navy",
              mr: { sm: "1rem" },
            }}
            error={errors?.email}
            helperText={
              (errors.email?.type === "required" && "Email is required.") ||
              (errors.email?.type === "pattern" &&
                "Email must be a valid email address.")
            }
          />
          <HelpfulHeroesPhoneInput
            name="phoneNumber"
            control={control}
            defaultValue={user?.phoneNumber}
            controller
            id="phone-input"
            placeholder="Mobile Phone Number*"
            sx={{
              width: { lg: "100%", md: "40%", xs: "100%" },
              alignSelf: { xs: "center", sm: "start" },
              color: "navy",
            }}
            error={errors?.phone}
            helperText={errors?.phone && "Mobile Phone Number is required."}
          />
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <Typography sx={{ mb: "0.8rem" }} variant="h5">
          Home Address
        </Typography>
        <LocationInput
          value={locationValue}
          setValue={setLocationValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          formKey="addressAttributes.fullAddress"
          setFormValue={setValue}
          error={!!errors["addressAttributes.fullAddress"]}
          helperText={errors["addressAttributes.fullAddress"]?.message}
        />
      </div>
      <Button
        type="submit"
        color="navy"
        variant="contained"
        sx={{
          width: { md: "194px", sm: "194px", xs: "70%" },
          alignSelf: { xs: "center", md: "start", sm: "start" },
          color: "white",
        }}
      >
        Save Changes
      </Button>
    </form>
  );
};

export default PersonalInfoForm;
