import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Button } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles((theme) => ({
  addFileButton: {
    backgroundColor: "#fafafa !important",
    borderRadius: "0.5rem",
    width: "10rem",
    height: "10rem",
    display: "flex",
    flexDirection: "column",
  },
  images: {
    display: "flex",
    flexDirection: "row",
  },
  files: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    paddingTop: "42px",
  },
  createProjectHeader: {},
}));

const UploadImage = ({
  addFile,
  index,
  removeFile,
  uploadedFile,
  isAttachFiles,
}) => {
  const classes = useStyles();
  const hiddenFileInput = React.useRef();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    addFile(index, event.target.files[0]);
  };

  const deleteFile = () => {
    const dt = new DataTransfer();
    dt.items.add(new File([], null));
    hiddenFileInput.current.files = dt.files;
    removeFile(index);
  };

  return (
    <div>
      {(!uploadedFile && (
        <>
          {(!isAttachFiles && (
            <Button
              type="button"
              className={classes.addFileButton}
              onClick={handleClick}
            >
              <Avatar
                style={{
                  backgroundColor: "#2b8ced",
                  width: "1.6rem",
                  height: "1.6rem",
                  marginBottom: "0.5rem",
                }}
              >
                <AddIcon style={{ color: "white" }} />
              </Avatar>
              Add files
            </Button>
          )) || <AttachFileIcon onClick={handleClick} />}
        </>
      )) || (
        <>
          {!isAttachFiles && (
            <div className={classes.images} style={{ marginTop: "10px" }}>
              <img
                style={{ width: "50px", marginRight: "5px" }}
                src={uploadedFile.data || URL.createObjectURL(uploadedFile)}
                alt=""
              />
              <Typography>{`Image ${index + 1}`}</Typography>
              <CloseIcon
                onClick={deleteFile}
                sx={{ width: "20px", mr: "10px" }}
              />
            </div>
          )}
        </>
      )}
      <input
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        id={index}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

UploadImage.propTypes = {
  addFile: PropTypes.func,
  removeFile: PropTypes.func,
  index: PropTypes.number,
  uploadedFile: PropTypes.object,
};

export default UploadImage;
