import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Typography,
  Avatar,
  Divider,
  IconButton,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import TopBar from "../../components/shared/TopBar";
import BottomBar from "../../components/shared/BottomBar";
import ProjectDetailSecondColumn from "../../components/ProjectDetailColumns/secondColumn";
import {
  getProjectById,
  resetProjectDetails,
} from "../../redux/actions/projectActions";
import { useDispatch } from "react-redux";
import { useProjectDetails } from "../../hooks/useProject";
import { useChipColor } from "../../hooks/useStatus";

const useStyles = makeStyles((theme) => ({
  projectDetailPadding: {
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
  },
  projectDetailRow: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "lightGrey",
    marginRight: "0.688rem",
  },
  avatarText: {
    marginLeft: "0.875rem",
  },
  icon: {
    width: "1.125rem",
    color: "#002653",
  },
  twoColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  firstColumn: {
    marginTop: "2.875rem",
    marginRight: "4.125rem",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "100%",
    },
  },
}));

const ProjectDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const { projectDetails } = useProjectDetails();
  const { chipColor } = useChipColor(projectDetails?.status);

  const renderSwitch = () => {
    switch (projectDetails?.preferredDate) {
      case "less_than_a_week":
        return "<1 week";
      case "flexible":
        return "Flexible";
      case "one_to_two_weeks":
        return "1-2 weeks";
      case "more_than_two_weeks":
        return "2+ weeks";
      default:
        return "Flexible";
    }
  };

  React.useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    dispatch(resetProjectDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = () => {
    localStorage.setItem("serviceLocal", projectDetails?.service?.name);
    navigate("/new-project");
  };

  const handleCancel = () => {
    navigate(`/cancel-project/${id}`);
  };

  return (
    <div>
      <TopBar />
      <div className={classes.projectDetailPadding}>
        <IconButton
          sx={{
            paddingLeft: "0",
            alignSelf: "start",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => navigate("/my-projects")}
        >
          <ArrowBackIcon sx={{ marginRight: "0.9rem" }} color="grey" />
          <Typography variant="body3" color="grey">
            Back
          </Typography>
        </IconButton>
        <div className={classes.titleRow}>
          <Typography variant="title2">{projectDetails?.title}</Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              color="grey"
              sx={{
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => handleCancel()}
            >
              Cancel Project
            </Button>
            <Button
              color="navy"
              sx={{
                width: 194,
                display: { xs: "none", sm: "flex" },
                color: "white",
              }}
              onClick={() => handleEdit()}
              variant="contained"
            >
              Edit Project
            </Button>
          </div>
        </div>
        <div className={classes.projectDetailRow}>
          <Chip
            label={projectDetails?.status}
            sx={{
              backgroundColor: chipColor,
              color: "#fff",
              textTransform: "capitalize",
              marginRight: "0.875rem",
            }}
          />
          <Typography variant="body3" color="#acacac">
            {new Date(projectDetails?.createdAt).toLocaleDateString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </Typography>
        </div>
        <div className={classes.twoColumns}>
          <div className={classes.firstColumn}>
            <div
              className={classes.projectDetailRow}
              style={{ marginBottom: "0.75rem" }}
            >
              <img
                alt="icon"
                src={projectDetails?.service?.icon}
                color="#002653"
                style={{
                  height: "2.625rem",
                  width: "2.625rem",
                  marginRight: "0.6rem",
                  objectFit: "scale-down",
                }}
              />
              <Typography variant="h5" color="#002653">
                {projectDetails?.service?.name}
              </Typography>
            </div>
            <div
              className={classes.projectDetailRow}
              style={{ marginBottom: "0.75rem" }}
            >
              <Avatar
                className={classes.avatar}
                style={{
                  height: "2.625rem",
                  width: "2.625rem",
                }}
              >
                <PlaceIcon
                  style={{ width: "1.125rem" }}
                  className={classes.icon}
                />
              </Avatar>
              <Typography variant="h5" color="#002653">
                {projectDetails?.address?.fullAddress}
              </Typography>
            </div>
            <div
              className={classes.projectDetailRow}
              style={{ marginBottom: "0.75rem" }}
            >
              <Avatar
                className={classes.avatar}
                style={{ height: "2.625rem", width: "2.625rem" }}
              >
                <AccessTimeIcon
                  style={{ width: "1.125rem" }}
                  className={classes.icon}
                />
              </Avatar>
              <Typography variant="h5" color="#002653">
                {renderSwitch()}
              </Typography>
            </div>
            <div
              className={classes.projectDetailRow}
              style={{ marginBottom: "0.75rem" }}
            >
              <Avatar
                className={classes.avatar}
                style={{ height: "2.625rem", width: "2.625rem" }}
              >
                <AttachMoneyIcon
                  style={{ width: "1.125rem" }}
                  className={classes.icon}
                />
              </Avatar>
              <Typography variant="h5" color="#002653">
                {`$${projectDetails?.service?.lowestAvgCost} - $${projectDetails?.service?.highestAvgCost}`}
              </Typography>
            </div>
            <Divider />
            {projectDetails?.fireFighter && (
              <>
                <div className={classes.projectDetailRow}>
                  <Avatar
                    style={{
                      height: "3.125rem",
                      width: "3.125rem",
                      backgroundColor: "#022752",
                      marginTop: "1.313rem",
                      marginBottom: "1.313rem",
                    }}
                    src={projectDetails?.fireFighter?.profilePicture}
                  />
                  <div className={classes.avatarText}>
                    <Typography variant="h5" color="#002653">
                      Accepted by {projectDetails?.fireFighter?.fullName}
                    </Typography>
                    <Typography
                      style={{ marginTop: "0.25rem" }}
                      variant="body2"
                    >
                      {projectDetails?.fireFighter?.city}
                    </Typography>
                  </div>
                </div>
                <Divider />
              </>
            )}
          </div>
          <ProjectDetailSecondColumn project={projectDetails} />
          <Button
            color="blue"
            sx={{
              width: 194,
              display: { xs: "flex", sm: "none" },
              alignSelf: "center",
              color: "white",
              mb: "1rem",
            }}
            onClick={() => handleEdit()}
            variant="contained"
          >
            Edit Project
          </Button>
          <div
            style={{
              marginBottom: "10rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="grey"
              onClick={() => handleCancel()}
              sx={{
                width: 194,
                display: { xs: "flex", sm: "none" },

                textAlign: "center",
              }}
            >
              Cancel Project
            </Button>
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default ProjectDetail;
