import { combineReducers } from "redux";
import localForage from "localforage";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import session from "./sessionReducer";
import statusReducer from "./statusReducer";
import { projectReducer } from "../slices/projectSlice";

const sessionPersistConfig = {
  key: "session",
  storage: localForage,
  whitelist: ["authenticated", "info", "user"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, session),
  project: projectReducer,
  statusReducer,
});

export default rootReducer;
