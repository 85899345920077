/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ListItemText,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import options from "./menuOptions";

const MyAccountMenuList = ({
  selectedSection,
  setSelectedSection,
  section,
  setSection,
}) => {
  React.useEffect(() => {
    if (!section) {
      setSection("personal");
      setSelectedSection("personal");
    }
  }, []);

  return (
    <MenuList>
      {options.map((item, index) => (
        <MenuItem
          onClick={() => {
            setSection(item.section);
            setSelectedSection(item.section);
          }}
          key={index}
          sx={{ bgcolor: "white", mb: "1rem" }}
        >
          <ListItemIcon>
            {item.icon(
              selectedSection === item.section ? "#002E65" : "#002653",
              selectedSection === item.section ? "1" : "0.5"
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={selectedSection === item.section ? "#002E65" : "#002653"}
              sx={{ opacity: selectedSection === item.section ? "1" : "0.5" }}
              variant="h5"
            >
              {item.name}
            </Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MyAccountMenuList;
