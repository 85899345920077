/* eslint-disable import/no-anonymous-default-export */
import { useSelector } from "react-redux";

/**
 * useStatus hook
 *
 * @param {string} action Prefix for the action names
 *
 * @returns {object} Object with status and error keys
 *
 * @example
 * const { status, error } = useStatus(login)
 */

export const useStatus = (action) => {
  return {
    status: useSelector(({ statusReducer }) => {
      const { status, error } = statusReducer[action?.typePrefix] || {};
      return {
        status,
        error,
      };
    }),
  };
};

export const useChipColor = (status) => {
  return {
    chipColor: useSelector(() => {
      if (status === "completed") {
        return "#43b12e";
      } else if (
        status === "pending" ||
        status === "assigned" ||
        status === "unassigned"
      ) {
        return "#2b8ced";
      } else if (status === "cancelled") {
        return "#B90E31";
      }
    }),
  };
};
