import * as yup from "yup";

export const validationSchema = yup.object({
  addressAttributes: yup.object({
    fullAddress: yup.lazy((value) =>
      typeof value === "object"
        ? yup
            .object()
            .required("Location is required.")
            .typeError("Location is required.") // typeError is necessary here, otherwise we get a bad-looking yup error
        : yup.string().required("Location is required.")
    ),
  }),
});
