import React from "react";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useProjectDetails } from "../../../hooks/useProject";

const useStyles = makeStyles((theme) => ({
  handymanContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "2rem",
  },
  handymanOptions: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
}));

const Handyman = ({ control, setDescriptionFormValue }) => {
  const classes = useStyles();
  const { projectDetails } = useProjectDetails();
  const [preferredDate, setPreferredDate] = React.useState(
    projectDetails?.preferredDate || "flexible"
  );

  return (
    <div className={classes.handymanContainer}>
      <Typography variant="title1">
        When would you like your handyman?
      </Typography>
      <div className={classes.handymanOptions}>
        <Button
          variant="contained"
          color={(preferredDate === "flexible" && "navy") || "lightGrey"}
          style={{
            color: (preferredDate === "flexible" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={async () => {
            setPreferredDate("flexible");
            setDescriptionFormValue("preferredDate", "flexible");
          }}
          name="flexible"
          control={control}
          controller
          children="I’m Flexible"
        />
        <Button
          variant="contained"
          color={
            (preferredDate === "less_than_a_week" && "navy") || "lightGrey"
          }
          style={{
            color: (preferredDate === "less_than_a_week" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={async () => {
            setPreferredDate("less_than_a_week");
            setDescriptionFormValue("preferredDate", "less_than_a_week");
          }}
          name="lessOneWeek"
          control={control}
          controller
          children="<1 week"
        />
        <Button
          variant="contained"
          color={
            (preferredDate === "one_to_two_weeks" && "navy") || "lightGrey"
          }
          style={{
            color: (preferredDate === "one_to_two_weeks" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={async () => {
            setPreferredDate("one_to_two_weeks");
            setDescriptionFormValue("preferredDate", "one_to_two_weeks");
          }}
          name="oneTwoWeeks"
          control={control}
          controller
          children="1-2 Weeks"
        />
        <Button
          variant="contained"
          color={
            (preferredDate === "more_than_two_weeks" && "navy") || "lightGrey"
          }
          style={{
            color:
              (preferredDate === "more_than_two_weeks" && "white") || "navy",
            marginRight: 8,
          }}
          onClick={async () => {
            setPreferredDate("more_than_two_weeks");
            setDescriptionFormValue("preferredDate", "more_than_two_weeks");
          }}
          name="2+weeks"
          control={control}
          controller
          children="2+ weeks"
        />
      </div>
    </div>
  );
};

export default Handyman;
