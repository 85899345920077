import React from "react";
import { Navigate } from "react-router-dom";
import useSession from "../../../hooks/useSession";

export const PrivateRoute = ({ children }) => {
  const { authenticated } = useSession();
  return authenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
