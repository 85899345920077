import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import TopBar from "../../components/shared/TopBar";
import { signUp } from "../../redux/actions/userActions";
import {
  Typography,
  Link,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import useSession from "../../hooks/useSession";
import { useStatus } from "../../hooks/useStatus";
import {
  FULFILLED,
  PENDING,
} from "../../redux/constants/actionStatusConstants";
import HelpfulHeroesTextField from "../../components/shared/HelpfulHeroesTextField";
import { validationSchema } from "./validations";
import { Navigate } from "react-router";
import { resetSignUpStatus } from "../../redux/actions/statusActions";
import { useYupValidationResolver } from "../../helpers/useValidationResolver";
import { getUser } from "../../redux/actions/userActions";
import helpfulHeroesIcon from "../../assets/icons/helpful-heroes-shield-light-background-2.svg";
import { LEGAL_LINKS } from "../../utils/contants";

const useStyles = makeStyles((theme) => ({
  signUpBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "3rem",
    },
  },
  signUpForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
  },
  signUpFormRow: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resolver = useYupValidationResolver(validationSchema);
  const { authenticated, user } = useSession();
  const { status, error } = useStatus(signUp);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const response = await dispatch(signUp(data));
    if (response?.meta?.requestStatus === FULFILLED) {
      navigate(`/verify-account?phoneNumber=${data.phoneNumber}`);
    }
  };

  React.useEffect(() => {
    error &&
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
    error && dispatch(resetSignUpStatus());
  }, [error, dispatch]);

  React.useEffect(() => {
    if (authenticated) {
      async function indentifyUser() {
        const userData = await dispatch(getUser(user));

        window.analytics.identify(userData.payload.user.id, {
          email: userData.payload.user.email,
          first_name: userData.payload.user.firstName,
          last_name: userData.payload.user.lastName,
        });
      }
      indentifyUser();
    }
  });

  if (authenticated && !!!user?.verifiedAt) {
    toast.error(
      <Typography color="error">
        Before continue. You must verify your account to continue.
      </Typography>,
      {
        position: "top-center",
      }
    );
    return <Navigate to={`/verify-account?phoneNumber=${user?.phoneNumber}`} />;
  }

  if (authenticated) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className={classes.signUp}>
      <IconButton
        size="large"
        edge="start"
        sx={{ padding: 3, marginTop: "2rem" }}
        onClick={() => navigate("/")}
      >
        <img alt="helpful heroes" src={helpfulHeroesIcon} width="55px" />
      </IconButton>

      <div className={classes.signUpBody}>
        <Typography variant="h3">Create your account</Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.signUpForm}>
          <div className={classes.signUpFormRow}>
            <HelpfulHeroesTextField
              handleChange={(e) => setValue("firstName", e.target.value)}
              placeholder="First Name"
              sx={{ width: "100%", mr: "1rem" }}
              className={classes.contactInformationFormRowInput}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
            />
            <HelpfulHeroesTextField
              handleChange={(e) => setValue("lastName", e.target.value)}
              placeholder="Last Name"
              sx={{ width: "100%" }}
              className={classes.contactInformationFormRowInput}
              error={errors?.lastName}
              helperText={errors?.lastName?.message}
            />
          </div>
          <HelpfulHeroesTextField
            handleChange={(e) => setValue("email", e.target.value)}
            placeholder="Email Address"
            sx={{ width: "100%", mr: "1rem" }}
            className={classes.contactInformationFormRowInput}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
          <HelpfulHeroesTextField
            handleChange={(e) => setValue("phoneNumber", e.target.value)}
            id="phone-input"
            placeholder="Mobile Phone Number"
            sx={{ width: "100%", my: "1rem" }}
            className={classes.contactInformationFormRowInput}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.message}
          />
          <HelpfulHeroesTextField
            handleChange={(e) => setValue("password", e.target.value)}
            placeholder="Password"
            sx={{ width: "100%" }}
            className={classes.contactInformationFormRowInput}
            type="password"
            error={errors?.password}
            helperText={errors?.password?.message}
          />
          <Typography
            sx={{
              my: "1rem",
              alignSelf: "center",
              textAlign: "center",
              width: { md: "80%" },
            }}
            variant="body1"
          >
            By clicking Create Account, you agree to the{" "}
            <Link onClick={() => window.open(LEGAL_LINKS.TERMS_OF_USE)}>
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link onClick={() => window.open(LEGAL_LINKS.PRIVACY_POLICY)}>
              Privacy Policy.
            </Link>
          </Typography>
          <Button
            type="submit"
            sx={{ color: "white" }}
            variant="contained"
            disabled={status === PENDING}
            color="navy"
          >
            {(status === PENDING && (
              <CircularProgress
                sx={{
                  color: "#002653",
                  width: "1.5rem !important",
                  height: "1.5rem !important",
                }}
              />
            )) ||
              "Continue"}
          </Button>
          <Typography sx={{ mt: "2rem" }} variant="h6">
            Already have an account?{" "}
            <Link onClick={() => navigate("/login")}>Log in.</Link>
          </Typography>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
