import React from "react";
import Template from "../../components/shared/Template";
import cancelProject from "../../assets/images/addTip.svg";
import { useNavigate } from "react-router";

const ProjectCancelled = () => {
  const navigate = useNavigate();
  return (
    <Template
      title="Project Cancelled"
      subtitle="Your project has been cancelled. Thanks for letting us know. Now, what else is on your to-do list?"
      image={cancelProject}
      handleClick={() => navigate("/")}
      button="Start Another Project"
    />
  );
};
export default ProjectCancelled;
