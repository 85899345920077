import * as yup from "yup";

export const validationSchema = yup.object({
  firstName: yup.string().required("First Name is a required field"),
  lastName: yup.string().required("Last Name is a required field"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Invalid Email format"),
  phoneNumber: yup.string().required("Mobile Phone Number is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters long"),
});
