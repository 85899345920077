import httpClient from "../httpClient";

class ProjectService {
  static getAllProjectTypes() {
    return httpClient.get("/services");
  }

  static getProjectChatBoard(id) {
    return httpClient.get(`/user/jobs/${id}/chat_boards`);
  }

  static getProjectById(id) {
    return httpClient.get(`/user/jobs/${id}`);
  }

  static postJob(job) {
    return httpClient.post("/user/jobs", job);
  }

  static updateJob(jobId, job) {
    return httpClient.put(`/user/jobs/${jobId}`, { job });
  }

  static cancelJob(id, reason) {
    return httpClient.post(
      `/user/jobs/${id}/cancel?job[cancelation_reason]=${reason}`
    );
  }

  static sendMessage(jobId, data) {
    return httpClient.post(`/user/jobs/${jobId}/chat_boards/messages`, data);
  }

  static verifyLocation(data) {
    return httpClient.get("/near", data);
  }

  static addTip(jobId, tip) {
    return httpClient.post(`/user/jobs/${jobId}/tip_fire_fighter`, { tip });
  }
}

export default ProjectService;
