import React from "react";
import useSelectedChatBoard from "../../hooks/useSelectedChatBoard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HelpfulHeroesTextField from "../shared/HelpfulHeroesTextField";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import { sendMessage } from "../../redux/actions/projectActions";
import UploadImageList from "../shared/UploadImageList";
import getBase64 from "../../helpers/getBase64";
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FULFILLED,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import useSession from "../../hooks/useSession";
import { useChipColor } from "../../hooks/useStatus";

const useStyles = makeStyles((theme) => ({
  leftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "30%",
  },
  rightBlock: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  messagesBody: {
    display: "flex",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  messagesContainer: {
    height: "60vh",
    overflowY: "scroll",
    marginBottom: "2rem",
  },
  listItem: {
    padding: "0 !important",
    alignItems: "center !important",
    cursor: "pointer",
  },
}));

const ChatMessages = ({ jobId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messagesContainerRef = React.useRef(null);
  const { user } = useSession();
  const { selectedChatBoard } = useSelectedChatBoard();
  const { chipColor } = useChipColor(selectedChatBoard?.jobStatus);
  const [message, setMessage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const searchTerm = "data:image/";

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [selectedChatBoard]);

  const onSubmit = async (e) => {
    const response = await dispatch(
      sendMessage({ jobId: jobId, message: { content: message } })
    );

    if (response.meta.requestStatus === FULFILLED) {
      setMessage("");
    }
    if (response.meta.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">
          There has been an error sending your message. Please try again or
          contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  const onInputEnter = async (e) => {
    if (e.key === "Enter") {
      const response = await dispatch(
        sendMessage({
          jobId: jobId,
          message: { content: message },
        })
      );
      if (response.meta.requestStatus === FULFILLED) {
        setMessage("");
      }
      if (response.meta.requestStatus === REJECTED) {
        toast.error(
          <Typography color="error">
            There has been an error sending your message. Please try again or
            contact support.
          </Typography>,
          {
            position: "top-center",
          }
        );
      }
    }
  };

  const handleSendImage = (file) => {
    setImages(images.concat({ data: file, filename: "job-image.png" }));
    images.concat({ data: file, filename: "job-image.png" })?.map((img) => {
      return dispatch(
        sendMessage({ jobId: jobId, message: { content: img.data } })
      );
    });
    setImages([]);
  };

  const onAddImage = (file) => {
    getBase64(file, handleSendImage);
  };

  const onRemoveImage = (index) => {
    const newArray = [...images];
    newArray.splice(index, 1);
    setImages(newArray);
  };

  return (
    <>
      {selectedChatBoard?.jobImage !== undefined && (
        <div className={classes.rightBlock}>
          <div className={classes.rightBlockHeader}>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  sx={{
                    width: "2.8rem",
                    height: "2.8rem",
                    marginRight: "1.5rem",
                    bgcolor: "background.paper",
                  }}
                  alt="Remy Sharp"
                  src={selectedChatBoard?.jobImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h3">
                    {selectedChatBoard?.jobTitle}
                  </Typography>
                }
                secondary={
                  <Typography sx={{ my: "0.1rem" }} variant="body2">
                    {selectedChatBoard?.jobService}
                  </Typography>
                }
              />
              <Chip
                label={selectedChatBoard?.jobStatus}
                sx={{
                  backgroundColor: chipColor,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              />
            </ListItem>
            <Divider sx={{ my: "0.7rem" }} />
          </div>
          <div className={classes.messagesContainer}>
            {selectedChatBoard?.messages
              ?.map((message, index) => {
                return (
                  <ListItem
                    key={index}
                    className={classes.listItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          width: "2.8rem",
                          height: "2.8rem",
                          marginRight: "1.5rem",
                          bgcolor: "background.paper",
                        }}
                        alt="Remy Sharp"
                        style={{
                          backgroundColor: message?.sent
                            ? "#f1f1f1"
                            : "#002653",
                        }}
                        src={
                          !message?.sent
                            ? selectedChatBoard?.contact?.image
                            : user.profilePicture
                        }
                      >
                        {`${selectedChatBoard?.contact?.name.charAt(0)}`}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex" }}>
                          <Typography variant="title3">
                            {message?.contactName}
                          </Typography>
                          <Typography variant="body1">
                            {new Date(message?.createdAt).toLocaleDateString(
                              "default",
                              {
                                minute: "2-digit",
                                hour: "2-digit",

                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </span>
                      }
                      secondary={
                        message?.content?.lastIndexOf(searchTerm) ? (
                          <Typography
                            sx={{ my: "0.1rem", color: "#333333" }}
                            variant="h7"
                          >
                            {message?.content}
                          </Typography>
                        ) : (
                          <img
                            alt="img"
                            src={message.content}
                            className={classes.messagesContainer}
                          />
                        )
                      }
                    />
                  </ListItem>
                );
              })
              ?.reverse()}
            <div ref={messagesContainerRef} />
          </div>
          <HelpfulHeroesTextField
            onKeyPress={onInputEnter}
            value={message}
            handleChange={(e) => setMessage(e.target.value)}
            placeholder="Write a message..."
            sx={{ width: "100%", marginBottom: "100px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onSubmit} edge="end">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <UploadImageList
                      images={images}
                      onAddImage={onAddImage}
                      onRemoveImage={onRemoveImage}
                      isAttachFiles
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default ChatMessages;
