import React from "react";
import { Typography } from "@mui/material";
import UploadImageList from "../../shared/UploadImageList";
import getBase64 from "../../../helpers/getBase64";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  uploadPhotos: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "2rem",
  },
}));

const UploadPhotos = ({ setDescriptionFormValue, getDescriptionFormValue }) => {
  const classes = useStyles();
  const [images, setImages] = React.useState(getDescriptionFormValue("images"));

  const addImage = (file) => {
    setImages(images.concat({ data: file, filename: "job-image.png" }));
    setDescriptionFormValue(
      "images",
      images.concat({ data: file, filename: "job-image.png" })
    );
  };

  const onAddImage = (file) => {
    getBase64(file, addImage);
  };

  const onRemoveImage = (index) => {
    const newArray = [...images];
    newArray.splice(index, 1);
    setImages(newArray);
    setDescriptionFormValue("images", newArray);
  };
  return (
    <div className={classes.uploadPhotos}>
      <Typography variant="title1">Upload photos (optional)</Typography>
      <UploadImageList
        images={images}
        onAddImage={onAddImage}
        onRemoveImage={onRemoveImage}
        isAttachFiles={false}
      />
    </div>
  );
};

export default UploadPhotos;
