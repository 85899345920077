import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import configureStore from "./redux/store";
import httpClient from "./httpClient";
import applyDefaultInterceptors from "./httpClient/applyDefaultInterceptors";
import { StyledEngineProvider } from "@mui/material";

const { persistor, store } = configureStore();

applyDefaultInterceptors(store, httpClient);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
