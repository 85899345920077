import React from "react";
import PropTypes from "prop-types";
import UploadImage from "../UploadImage";

const UploadImageList = ({
  images,
  onAddImage,
  onRemoveImage,
  isAttachFiles,
}) => {
  const initialState = (images.length > 0 && [...images, null]) || [null];
  const [files, setFiles] = React.useState(initialState);

  const removeFile = (index) => {
    const newArray = [...files];
    newArray.splice(index, 1);
    setFiles(newArray);
    onRemoveImage(index);
  };

  const addFile = (index, file) => {
    const newArray = [...files];
    newArray[index] = file;
    newArray[newArray.length] = null;
    setFiles(newArray);
    onAddImage(file);
  };

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {files.map((file, index) => (
          <div key={index}>
            <UploadImage
              addFile={addFile}
              removeFile={removeFile}
              index={index}
              uploadedFile={file}
              isAttachFiles={isAttachFiles}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

UploadImageList.propTypes = {
  onAddImage: PropTypes.func,
  onRemoveImage: PropTypes.func,
};

export default UploadImageList;
