import React from "react";
import { useDispatch } from "react-redux";
import { Card, Typography, Avatar, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/shared/TopBar";
import BottomBar from "../../components/shared/BottomBar";
import {
  getAllProjectTypes,
  resetProjectDetails,
} from "../../redux/actions/projectActions";
import { useAllProjectTypes } from "../../hooks/useProject";

const useStyles = makeStyles((theme) => ({
  letsGetStartedContainer: {
    display: "flex",
    flexDirection: "column",
  },
  letsGetStartedSubtitle: {
    marginTop: "0.8rem !important",
    marginBottom: "1.8rem !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      alignSelf: "center",
    },
  },
  cardsContainer: {
    paddingLeft: "10.3rem",
    paddingRight: "10.3rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
}));

const LetsGetStarted = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allProjectTypes } = useAllProjectTypes();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getAllProjectTypes());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(resetProjectDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (item) => {
    localStorage.setItem("serviceLocal", item.name);
    navigate("/new-project");
  };

  return (
    <div className={classes.letsGetStartedContainer}>
      <TopBar />
      <Typography variant="h1">Let's get started</Typography>
      <Typography variant="h6" className={classes.letsGetStartedSubtitle}>
        Select a service below to start creating your project request.
      </Typography>
      {allProjectTypes && (
        <Grid
          className={classes.cardsContainer}
          container
          columns={{ xs: 4, sm: 6, md: 8, lg: 10 }}
        >
          {allProjectTypes?.map((item, index) => (
            <Grid item xs={2} sm={3} md={4} lg={2} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                }}
                onClick={() => handleClick(item)}
              >
                <Avatar>
                  <img
                    style={{ width: "2.3rem", height: "auto" }}
                    alt=""
                    src={item.icon}
                  />
                </Avatar>
                <Typography variant="h5" marginTop="14.4px">
                  {item.name}
                </Typography>
                <Typography
                  sx={{ mt: "0.4rem" }}
                  variant="subtitle1"
                  color="secondary"
                >{`$${item?.lowestAvgCost} – $${item?.highestAvgCost}`}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <BottomBar />
    </div>
  );
};

export default LetsGetStarted;
