import { createAsyncThunk } from "@reduxjs/toolkit";
import ProjectService from "../../services/projectService";
import parseError from "../../utils/parseError";

export const getAllProjectTypes = createAsyncThunk(
  "project/getAll",
  async () => {
    try {
      const { data } = await ProjectService.getAllProjectTypes();
      return data;
    } catch ({ response: { data } }) {
      console.error(data);
    }
  }
);

export const getProjectChatBoard = createAsyncThunk(
  "project/chatBoards",
  async (id) => {
    try {
      const { data } = await ProjectService.getProjectChatBoard(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectById = createAsyncThunk(
  "project/getById",
  async (id) => {
    try {
      const { data } = await ProjectService.getProjectById(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const postJob = createAsyncThunk("user/jobs", async (job) => {
  try {
    const { data } = await ProjectService.postJob(job);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const updateJob = createAsyncThunk("project/update", async (payload) => {
  try {
    const { data } = await ProjectService.updateJob(
      payload?.jobId,
      payload.job
    );
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const cancelJob = createAsyncThunk("project/cancel", async (payload) => {
  try {
    const { data } = await ProjectService.cancelJob(
      payload?.id,
      payload?.cancelReason
    );
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const sendMessage = createAsyncThunk(
  "project/sendMessage",
  async (payload) => {
    try {
      const { data } = await ProjectService.sendMessage(payload.jobId, {
        message: payload.message,
      });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const verifyLocation = createAsyncThunk(
  "project/verifyLocation",
  async (payload) => {
    try {
      const { data } = await ProjectService.verifyLocation(
        payload.fireFightersNear,
        {
          fireFightersNear: payload.fireFightersNear,
        }
      );
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addTip = createAsyncThunk("project/addTip", async (payload) => {
  try {
    const { data } = await ProjectService.addTip(payload.jobId, payload.tip);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const resetProjectDetails = createAsyncThunk(
  "project/resetProjectDetails",
  async () => {}
);

export const { fulfilled: getAllProjectTypesFulfilled } = getAllProjectTypes;
export const { fulfilled: resetProjectDetailsFulfilled } = resetProjectDetails;
export const { fulfilled: updateJobFulfilled } = updateJob;
export const { fulfilled: getProjectChatBoardFulfilled } = getProjectChatBoard;
export const { fulfilled: postJobsFulfilled } = postJob;
export const { fulfilled: cancelJobFulfilled } = cancelJob;
export const { fulfilled: sendMessageFulfilled } = sendMessage;
export const { fulfilled: getProjectByIdFulfilled } = getProjectById;
export const { fulfilled: verifyLocationFulfilled } = verifyLocation;
export const { fulfilled: addTipFulfilled } = addTip;
